import Grid from '@mui/material/Unstable_Grid2';
import {Button, Link} from '@mui/material';
import { styled } from '@mui/material/styles';
//import useMediaQuery from '@mui/material/useMediaQuery';
import Facebook from './Facebook';
import Instagram from './Instagram';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'cover',
  width:'100%',
  height:'100%',  
});

export default function Home() {
  //const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
  <>   
    <div style={{position:'absolute', width:'100%', top:'8px', zIndex:999}}>
      <p style={{ letterSpacing:'clamp(0px, 1.3vw, 1.3rem)',
        fontSize:'clamp(0.4rem, 1.2vw, 1.2rem)', fontWeight:'400', padding:'0px', textAlignLast:'center', color:'#fff', width:'99%', margin:'auto'
      }}>
      SCOPRI UNA SELEZIONE DI OLTRE 100 PREMIUM BRAND
      </p>
    </div> 
    <Grid container rowSpacing={0.2} columnSpacing={0.2} disableEqualOverflow sx={{height:'100%'}}>
      <Grid xs={12} sm={12} display="flex" justifyContent="center" alignItems="center">
        <div style={{position:'relative', width:'100%', height:'100%'}}>
          <Link href='https://www.imatra.com' sx={{textDecoration:'none'}}>
            <Img alt="nob 4.0" src="/images/not-only-bike.jpg" />
            <div style={{position:'absolute', color:'#fff', left:'50%',top:'50%', transform:'translate(-50%, -50%)', width:'max-content', textAlign:'center'}}>
              <p style={{fontSize:'6vw', fontWeight:'800', margin:'0px', padding:'0px', lineHeight: '1'}}>
                <img src="/images/nob-shop.png" alt="shop nob" style={{width:'300px'}} />
              </p>            
            </div>
          </Link>
        </div>
      </Grid>
      <Grid xs={12} sm={6} display="flex" justifyContent="center" alignItems="center">
          <div style={{position:'relative', width:'100%', height:'100%'}}>
            <Link href='https://www.imatra.com/brand/maap' sx={{textDecoration:'none'}}>
            <Img alt="nob race" src="/images/maap.jpg" loading="lazy" />          
            <div style={{position:'absolute', bottom:'clamp(2rem, 3.125vw, 2.5rem)', left:'clamp(2rem, 3.125vw, 2.5rem)', color:'#000'}}>              
              <p style={{fontSize:'clamp(1.65rem, 3vw, 2.3rem)', fontWeight:'700', margin:'0px 0px 24px 0px', padding:'0px', lineHeight: '0.7'}}>{'MAAP'}</p>                          
              <Button sx={{border:'solid 1px #fff', borderColor:'#000', color:'#000', borderRadius:'0px'}}>
                <span style={{fontSize:'clamp(0.7rem, 1.1vw, 0.87rem)'}}>{'SHOP NOW'}</span>
              </Button>                            
            </div>            
            </Link>
          </div>
        </Grid>
        <Grid xs={12} sm={6} display="flex" justifyContent="center" alignItems="center">
          <div style={{position:'relative', width:'100%', height:'100%'}}>
            <Link href='https://www.imatra.com/brand/pas-normal-studios' sx={{textDecoration:'none'}}>
              <Img alt="pas normal studios" src="/images/pas-normal-studios.jpg" loading="lazy" />          
              <div style={{position:'absolute', bottom:'clamp(2rem, 3.125vw, 2.5rem)', left:'clamp(2rem, 3.125vw, 2.5rem)', color:'#fff'}}>              
                <p style={{fontSize:'clamp(1.65rem, 3vw, 2.3rem)', fontWeight:'700', margin:'0px 0px 24px 0px', padding:'0px', lineHeight: '0.7'}}>{'PAS NORMAL STUDIOS'}</p>                          
                <Button sx={{border:'solid 1px #fff', borderColor:'#fff', color:'#fff', borderRadius:'0px'}}>
                  <span style={{fontSize:'clamp(0.7rem, 1.1vw, 0.87rem)'}}>{'SHOP NOW'}</span>
                </Button>              
              </div>            
            </Link>
          </div>
        </Grid>      
        <Grid xs={12} sm={6} display="flex" justifyContent="center" alignItems="center">
          <div style={{position:'relative', width:'100%', height:'100%'}}>
            <Link href='https://www.imatra.com/brand/assos' sx={{textDecoration:'none'}}>
              <Img alt="nob race" src="/images/assos.jpg" loading="lazy" />          
              <div style={{position:'absolute', bottom:'clamp(2rem, 3.125vw, 2.5rem)', left:'clamp(2rem, 3.125vw, 2.5rem)', color:'#fff'}}>              
                <p style={{fontSize:'clamp(1.65rem, 3vw, 2.3rem)', fontWeight:'700', margin:'0px 0px 24px 0px', padding:'0px', lineHeight: '0.7'}}>{'ASSOS'}</p>                          
                <Button sx={{border:'solid 1px #fff', borderColor:'#fff', color:'#fff', borderRadius:'0px'}}>
                  <span style={{fontSize:'clamp(0.7rem, 1.1vw, 0.87rem)'}}>{'SHOP NOW'}</span>
                </Button>              
              </div>            
            </Link>
          </div>
        </Grid>
        <Grid xs={12} sm={6} display="flex" justifyContent="center" alignItems="center">
          <div style={{position:'relative', width:'100%', height:'100%'}}>
            <Link href='https://www.imatra.com/brand/albaoptics' sx={{textDecoration:'none'}}>
              <Img alt="pas normal studios" src="/images/alba-optics.jpg" loading="lazy" />          
              <div style={{position:'absolute', bottom:'clamp(2rem, 3.125vw, 2.5rem)', left:'clamp(2rem, 3.125vw, 2.5rem)', color:'#fff'}}>              
                <p style={{fontSize:'clamp(1.65rem, 3vw, 2.3rem)', fontWeight:'700', margin:'0px 0px 24px 0px', padding:'0px', lineHeight: '0.7'}}>{'ALBA OPTICS'}</p>                          
                <Button sx={{border:'solid 1px #fff', borderColor:'#fff', color:'#fff', borderRadius:'0px'}}>
                  <span style={{fontSize:'clamp(0.7rem, 1.1vw, 0.87rem)'}}>{'SHOP NOW'}</span>
                </Button>              
              </div>            
            </Link>
          </div>
        </Grid>              
        <Grid xs={12} sm={12} display="flex" justifyContent="center" alignItems="center">
          <div style={{position:'relative', width:'100%', height:'100%'}}>
            <div style={{position:'relative', fontSize:'0.7rem', color:'#000', margin:'auto'}}>                
                <div style={{width:'100%', textAlign:'center', paddingTop:'35px'}}>
                  <Facebook to="https://www.facebook.com/notonlybike"/>
                  <Instagram to="https://www.instagram.com/notonlybike/" />
                </div>
                <p style={{width:'100%', textAlign:'center', paddingBottom:'35px', fontSize:'clamp(0.7rem, 1vw, 1rem)'}}>Sede in Via Marconi 2/A - 47030 San Mauro Pascoli (FC) - P.I. 04545490403 - Il marchio NOB è di proprietà della società NOB 4.0 S.r.l. - Società Benefit. &copy; Copyright 2023.</p>                  
            </div>                        
          </div>
        </Grid>
    </Grid>
    </>
  )
}